import type { Dispatch, GetState, ThunkAction } from '../../types';
import { toast } from 'react-toastify';

export const SET_USER = 'SET_USER';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';
export const RENDER_NOTIFICATION = 'RENDER_NOTIFICATION';
export const SET_ROLE_USER = 'SET_ROLE_USER';

const API_URL = '/api';
function getConfig() {
  return {
    headers: {
      Authorization: `Bearer ${
        typeof window !== 'undefined' && localStorage.getItem('auth-token')
          ? localStorage.getItem('auth-token')
          : 'invalid'
      }`,
    },
  };
}

export const verifyDuoResponse =
	(duoUser, duoCode, history): ThunkAction =>
	async (dispatch: Dispatch, getState: GetState, axios: any) => {
    const token = localStorage.getItem('duo-auth');
		const res = await axios.post(
			`${API_URL}/verifyDuoCode`,
			{ duoUser, duoCode, redirectUrl: `${window.location.origin}/admin/` },
		);

    if(res.data.success) {
      if (typeof window !== 'undefined') {
				localStorage.setItem('auth-token', res.data.token);
			}
			history.push('/admin/main/dashboard');
    }
		let displayUser = `${res.data.profile.name} ${res.data.profile.lastName}`;
		if (displayUser === '') displayUser = 'New User';
		dispatch({ type: SET_USER, user: res.data.user });
		dispatch({ type: SET_ROLE_USER, userRole: res.data.user.role });
		dispatch({ type: SET_PROFILE, profile: res.data.profile });
		dispatch({ type: SET_LOGIN_STATUS, status: '' });
		
		return res;
	};

/* export const verifyDuo = (form, history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  const signedResponse = form[0].value;
  const email = getState().context.primaryAuthReady.username;
  const res = await axios.post(
    `${API_URL}/postAction`,
    { signedResponse, email },
    getConfig()
  );
  let displayUser = `${res.data.profile.name} ${res.data.profile.lastName}`;
  if (displayUser === '') displayUser = 'New User';
  dispatch({ type: SET_USER, user: res.data.user });
  dispatch({ type: SET_PROFILE, profile: res.data.profile });
  dispatch({ type: SET_LOGIN_STATUS, status: '' });
  if (typeof window !== 'undefined') {
    localStorage.setItem('auth-token', res.data.token);
  }
  history.push('/admin/main/dashboard');
  return res;
}; */

export const login = (
  username: string,
  password: string,
  history,
): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  const res = await axios.post(`${API_URL}/authenticate/`, {
    email: username,
    encryptedPassword: password,
  });
  if (res.data.success) {
    const duoResp = await axios.post(
			`${API_URL}/duoUniversal`,
			{
				duoUser: username,
				redirectUrl: `${window.location.origin}/admin/`,
			},
      {
        headers: {
          Authorization: `Bearer ${res.data.token}`
        },
      }
		);

    if (duoResp.data.success) {
      dispatch({
        type: 'SET_ADMIN_PRIMARY_AUTH_READY',
        primaryAuthReady: { username, ready: true },
      });
      dispatch({ type: 'SET_ADMIN_DUO_AUTH_READY', duoReady: true });
      // dispatch({
      //   type: 'SET_DUO_SIGN_REQUEST',
      //   duoSignRequest: duoResp.data.signRequest,
      // });
      localStorage.setItem('duo-auth', duoResp.data.token);

      setTimeout(() => {
				if (typeof window !== 'undefined') {
					localStorage.setItem('duoUser', username);
					window.location.replace(duoResp.data.url);
				}
			}, 500);
    }
    
  } else {
    dispatch({ type: SET_LOGIN_STATUS, status: 'Authentication failed' });
    dispatch({
      type: RENDER_NOTIFICATION,
      notificationType: 'error',
      message: res.data.message,
      title: 'Login Error',
    });
  }
  return res;
};

export const loginToken = (history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('auth-token')) {
      const res = await axios.post(`${API_URL}/authenticate/token`, {
        token:
          typeof window !== 'undefined'
            ? localStorage.getItem('auth-token')
            : '',
      });
      if (res.data.success) {
        dispatch({ type: SET_USER, user: res.data.user });
        dispatch({ type: SET_ROLE_USER, userRole: res.data.user.role });
        dispatch({ type: SET_PROFILE, profile: res.data.profile });
        dispatch({ type: SET_LOGIN_STATUS, status: '' });
        if (typeof window !== 'undefined') {
          if (window.location.pathname !== '/admin') {
            window.location.reload();
          } else {
            history.push('/admin/main/dashboard');
          }
        }
        return res;
      } else {
        localStorage.removeItem('auth-token');
      }
    }
  }
};

export const temporalLogin = (email, rs, history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  const res = await axios.post(`${API_URL}/temporalLogin`, {
    email,
    rsToken: rs,
  });
  dispatch({ type: SET_USER, user: res.data.user });
  dispatch({ type: SET_ROLE_USER, userRole: res.data.user.role });
  dispatch({ type: SET_PROFILE, profile: res.data.profile });
  dispatch({ type: SET_LOGIN_STATUS, status: '' });
  if (typeof window !== 'undefined') {
    localStorage.setItem('rs-token', rs);
  }
  history.push(`/admin/main/profile?tmp=${true}`);
  return res;
};

export const sendVerificationEmail = (to): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  const res = await axios.post(`${API_URL}/sendVerificationEmailAdmin`, {
    username: to,
  });
  if (res.data.success) {
    dispatch({
      type: RENDER_NOTIFICATION,
      notificationType: 'success',
      message: res.data.message,
      title: 'Password Reset Successful',
    });
  } else {
    dispatch({
      type: RENDER_NOTIFICATION,
      notificationType: 'error',
      message: res.data.message,
      title: 'Error',
    });
  }
  return res;
};
