import type { Dispatch, ThunkAction } from '../../types';

const API_URL = '/api';
export const LOGOUT_CURRENT_USER = 'LOGOUT_CURRENT_USER';

export const refetchUser = (history): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('auth-token')) {
      const res = await axios.post(`${API_URL}/authenticate/token`, {
        token:
          typeof window !== 'undefined'
            ? localStorage.getItem('auth-token')
            : '',
      });
      if (res.data.success) {
        dispatch({ type: 'SET_USER', user: res.data.user });
        dispatch({ type: 'SET_PROFILE', profile: res.data.profile });
        dispatch({ type: 'SET_LOGIN_STATUS', status: '' });
      } else {
        history.push('/admin');
      }
      return res;
    }
  }
};

export const logout = (history: Object): ThunkAction => async (
  dispatch: Dispatch,
  getState: GetState,
  axios: any
) => {
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('auth-token')) {
      const res = await axios.post(`${API_URL}/logout`, {
        token:
          typeof window !== 'undefined'
            ? localStorage.getItem('auth-token')
            : '',
      });
      if (res.data.success) {
        history.push('/admin');
        localStorage.removeItem('auth-token');
        dispatch({ type: 'SET_USER', user: {} });
        dispatch({
          type: 'SET_ADMIN_PRIMARY_AUTH_READY',
          primaryAuthReady: {},
        });
        dispatch({ type: 'SET_ADMIN_DUO_AUTH_READY', duoReady: false });
        // dispatch({ type: 'SET_DUO_SIGN_REQUEST', duoSignRequest: {} });
      } else {
        history.push('/admin');
      }
      return res;
    }
  }
};

export const sessionExpire = (history: Object): ThunkAction => (
  dispatch: Dispatch
) => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('rs-token');
  }
  dispatch({ type: 'SHOW_SESSION_TIMEOUT_ALERT', sessionTimeout: false });
  dispatch({ type: 'SET_USER', user: {} });
  history.push('/admin');
  window.location.href = window.location.href;
};
