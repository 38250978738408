/* @flow */

import _ from 'lodash';
import { toast } from 'react-toastify';
import * as notify from './../../utils/notify';

import {
  SET_USER,
  SET_LOGIN_STATUS,
  SET_PROFILE,
  RENDER_NOTIFICATION,
  SET_ROLE_USER
} from './action';
import type { Context, Action } from '../../types';

type State = Context;

const initialState = {
  user: {},
  loginErrorStatus: '',
  profile: {},
  notificationType: '',
  message: '',
  title: '',
  showAlways: false,
  canLogIn: true,
  primaryAuthReady: {},
  duoReady: false,
  // duoSignRequest: {},
  userRole: '',
};

export default (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case SET_USER:
      return _.assign({}, state, { user: action.user });
    case SET_PROFILE:
      return _.assign({}, state, { profile: action.profile });
    case SET_LOGIN_STATUS:
      return _.assign({}, state, { loginErrorStatus: action.status });
    case 'RENDER_NOTIFICATION':
      const toastOpts = {
        position: 'top-right',
        autoClose: action.showAlways ? !action.showAlways : 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'toast-general-look',
      };
      switch (action.notificationType) {
        case 'success':
          toast.success(action.message, toastOpts);
          break;
        case 'warning':
          toast.warn(action.message, toastOpts);
          break;
        case 'error':
          toast.error(action.message, toastOpts);
          break;

        default:
          toast(action.message, toastOpts);
          break;
      }

      return _.assign({}, state, {
        notificationType: action.notificationType,
        message: action.message,
        title: action.title,
        showAlways: action.showAlways ? action.showAlways : false,
      });
    case 'HIDE_NOTIFICATION':
      toast.dismiss();
      return _.assign({}, state, {
        notificationType: '',
        message: '',
        title: '',
        showAlways: false,
      });
    case 'SET_CAN_LOG_IN':
      return _.assign({}, state, { canLogIn: action.canLogIn });
    case 'SET_ADMIN_PRIMARY_AUTH_READY':
      return _.assign({}, state, { primaryAuthReady: action.primaryAuthReady });
    case 'SET_ADMIN_DUO_AUTH_READY':
      return _.assign({}, state, { duoReady: action.duoReady });
    // case 'SET_DUO_SIGN_REQUEST':
    //   return _.assign({}, state, { duoSignRequest: action.duoSignRequest });
    case SET_ROLE_USER:
      return _.assign({}, state, { userRole: action.userRole });
    default:
      return state;
  }
};
